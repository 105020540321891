import { isDefined } from "@clipboard-health/util-ts";
import { IonContent } from "@ionic/react";
import { WorkedBreakConfirmation } from "@src/app/hcpShifts/components/workedBreakConfirmation";
import { SignatureContainerRef } from "@src/app/shiftSignature/signatureContainer";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { WorkedBreakAuthorizationParams } from "@src/appV2/Shifts/Shift/WorkedBreakAuthorization/WorkedBreakRequestCreationPage";
import { Shift, TimeRange } from "@src/lib/interface";
import { useCallback, useRef, useState } from "react";

import { HcfEmployeeNameInput } from "./hcfEmployeeNameInput";
import { HcfInfoInput } from "./hcfInfoInput";
import { HcfSignatureInput } from "./hcfSignatureInput";
import { HcpSignatureInput } from "./hcpSignatureInput";
import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "../../constants";

interface CaliforniaSignatureProps {
  onCloseOrCancel: () => void;
  shift: Shift;
  onSubmit: (
    files: SelectedFile[],
    hcpName: string | undefined,
    hcfEmployeeName: string,
    hcfStationWing: string,
    hcfNursingService: string
  ) => void;
  lunchInOutTimeRange: TimeRange;
  isUploadingImage: boolean;
  isShiftTimeEditOpen: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
  workedBreakAuthorization?: WorkedBreakAuthorizationParams;
  onStepChange: (currentStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  onOpenTimeEdit: () => void;
}

export function CaliforniaSignature(props: CaliforniaSignatureProps) {
  const {
    onCloseOrCancel,
    shift,
    onSubmit,
    lunchInOutTimeRange,
    isUploadingImage,
    isShiftTimeEditOpen,
    isSolveUnpaidBreaksEnabled,
    workedBreakAuthorization,
    onStepChange,
    onOpenTimeEdit,
  } = props;

  const [hcpSignature, setHcpSignature] = useState<string>("");
  const [hcfSignature, setHcfSignature] = useState<string>("");
  const [hcfEmployeeName, setHcfEmployeeName] = useState<string>("");
  const [hcfStationWing, setHcfStationWing] = useState<string>("");
  const [hcfNursingService, setHcfNursingService] = useState<string>("");

  const [isWorkedBreakStatementConfirmed, setIsWorkedBreakStatementConfirmed] = useState(false);

  const [californiaSignatureStep, setCaliforniaSignatureStep] = useState(
    DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE
  );
  const hcpSignaturePad = useRef<SignatureContainerRef>(null);
  const hcfSignaturePad = useRef<SignatureContainerRef>(null);

  const onClearHcpSignature = useCallback(() => {
    hcpSignaturePad?.current?.clearSignature();
    setHcpSignature("");
  }, []);
  const onClearHcfSignature = useCallback(() => {
    hcfSignaturePad?.current?.clearSignature();
    setHcfSignature("");
  }, []);

  const goToStep = (nextStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => {
    setCaliforniaSignatureStep(nextStep);
    onStepChange(nextStep);
  };

  const submitFile = () => {
    const hcpSignatureFile = {
      file: hcpSignature,
      type: "png",
    };
    const hcfSignatureFile = {
      file: hcfSignature,
      type: "png",
    };
    onSubmit(
      [hcpSignatureFile, hcfSignatureFile],
      shift?.agent?.name,
      hcfEmployeeName,
      hcfStationWing,
      hcfNursingService
    );
  };

  return (
    <>
      <IonContent>
        {californiaSignatureStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE && (
          <HcpSignatureInput
            form={{
              hcpSignature,
              setHcpSignature,
              isNextEnabled: !isSolveUnpaidBreaksEnabled || !isShiftTimeEditOpen,
            }}
            actions={{
              onCloseOrCancel,
              onClear: onClearHcpSignature,
              goToStep,
            }}
            signature={{
              hcpName: shift?.agent?.name,
              hcpSignaturePad,
            }}
          />
        )}

        {californiaSignatureStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_NAME && (
          <HcfEmployeeNameInput
            form={{
              hcfEmployeeName,
              setHcfEmployeeName,
            }}
            actions={{ goToStep }}
          />
        )}

        {californiaSignatureStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_INFO && (
          <HcfInfoInput
            form={{
              hcfStationWing,
              setHcfStationWing,
              hcfNursingService,
              setHcfNursingService,
            }}
            actions={{
              goToStep,
            }}
          />
        )}

        {(californiaSignatureStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE ||
          (californiaSignatureStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION &&
            !isDefined(workedBreakAuthorization))) && (
          <HcfSignatureInput
            form={{
              hcfSignature,
              setHcfSignature: (signatureData) => {
                setHcfSignature(signatureData);
              },
              onStrokeStarted: () => {
                if (workedBreakAuthorization && !isWorkedBreakStatementConfirmed) {
                  goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION);
                }
              },
            }}
            actions={{
              goToStep,
              onClear: onClearHcfSignature,
              submitFile,
            }}
            signature={{
              hcfEmpName: hcfEmployeeName,
              hcfSignaturePad,
              isUploadingImage,
              isShiftTimeEditOpen,
            }}
          />
        )}

        {workedBreakAuthorization &&
          californiaSignatureStep === DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_BREAK_CONFIRMATION && (
            <WorkedBreakConfirmation
              isTimeEditOpen={isShiftTimeEditOpen}
              lunchInOutTimeRange={lunchInOutTimeRange}
              workedBreakAuthorization={workedBreakAuthorization}
              onEditTimesClick={onOpenTimeEdit}
              onConfirmClick={() => {
                setIsWorkedBreakStatementConfirmed(true);
                setCaliforniaSignatureStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE);
              }}
            />
          )}
      </IonContent>
    </>
  );
}
