import { IonButton } from "@ionic/react";
import {
  SignatureContainer,
  SignatureContainerRef,
} from "@src/app/shiftSignature/signatureContainer";

import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "../../constants";

export const HcpSignatureInput = ({
  form,
  actions,
  signature,
}: {
  form: {
    hcpSignature: string;
    setHcpSignature: (n: string) => void;
    isNextEnabled: boolean;
  };
  actions: {
    onCloseOrCancel: () => void;
    onClear: () => void;
    goToStep: (nextStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  };
  signature: {
    hcpName?: string;
    hcpSignaturePad: React.RefObject<SignatureContainerRef>;
  };
}) => {
  const { hcpSignature, setHcpSignature, isNextEnabled } = form;
  const { onCloseOrCancel, onClear, goToStep } = actions;
  const { hcpName, hcpSignaturePad } = signature;

  return (
    <div id="hcpSignatureSection">
      <div className="signature-pad-name-item">
        <p className="signature-pad-name">
          {hcpName}
          <span>, please sign below.</span>
        </p>
      </div>
      <div className="signature-pad-canvas" aria-label="HCP Signature Pad">
        <SignatureContainer
          ref={hcpSignaturePad}
          signature={hcpSignature}
          onChange={setHcpSignature}
        />
      </div>

      <div className="sign-button-container">
        <IonButton
          fill="outline"
          shape="round"
          className="ion-margin sign-button"
          onClick={onCloseOrCancel}
        >
          Back
        </IonButton>
        <IonButton
          fill="outline"
          shape="round"
          className="ion-margin sign-button"
          onClick={onClear}
        >
          Clear
        </IonButton>
        <IonButton
          shape="round"
          color="primary"
          className="ion-margin sign-button"
          onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_NAME)}
          disabled={!hcpSignature || !isNextEnabled}
        >
          Next
        </IonButton>
      </div>
    </div>
  );
};
