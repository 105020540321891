import { useModalState } from "@clipboard-health/ui-react";
import { InternalLink } from "@clipboard-health/ui-react";
import { IonCardSubtitle, IonCol, IonGrid, IonIcon, IonLabel, IonRow, IonText } from "@ionic/react";
import { RootPaths } from "@src/appV2/App/paths";
import { useIsExtraTimePayEnabled } from "@src/appV2/Facilities/api/useIsExtraTimePayEnabled";
import { ExtraTimePayBottomSheet } from "@src/appV2/Facilities/components/ExtraTimePayBottomSheet";
import { calculateDistanceToFacilityInMiles } from "@src/appV2/Facilities/utils/index";
import { logEvent } from "@src/appV2/lib/analytics";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { USER_EVENTS } from "@src/constants";
import { usCountyCode } from "@src/constants/phone";
import { DistanceNote } from "@src/lib/deprecatedCode";
import { Facility, Shift } from "@src/lib/interface";
import { formatPhone } from "@src/lib/utils";
import { informationCircleOutline, stopwatchOutline } from "ionicons/icons";
import moment from "moment-timezone";

interface FacilityDetailsProps {
  shift: Shift;
  onFacilityDetailsClick?: (
    shift: Shift,
    facility: Facility,
    displayExtraTimePayCard: boolean
  ) => void;
  showCheckInInstructions: boolean;
  showMinimalDetails?: boolean;
}

export function FacilityDetails(props: FacilityDetailsProps) {
  const {
    shift,
    onFacilityDetailsClick,
    showCheckInInstructions = true,
    showMinimalDetails = false,
  } = props;
  const worker = useDefinedWorker();

  const facility = shift.facility!;
  const distanceInMiles = calculateDistanceToFacilityInMiles(worker, shift?.facility);

  const dayEnd = moment().endOf("day");
  const isShiftToday = dayEnd.isAfter(shift.start);

  const extraTimePayBottomSheetModalState = useModalState();

  const isExtraTimePayEnabled = useIsExtraTimePayEnabled(shift.facility);

  const onClickOnFacilityDetails = (displayExtraTimePayCard = false) => {
    onFacilityDetailsClick?.(shift, facility, displayExtraTimePayCard);
  };
  return (
    <>
      <IonGrid>
        <IonRow className="ion-justify-content-start ion-align-items-center ion-no-padding">
          <IonCol className="facility-info-icon-unverified ion-no-padding">
            <InternalLink
              to={`${RootPaths.HOME}/facility/${facility.userId}`}
              onClick={(event) => {
                event.stopPropagation();
                onClickOnFacilityDetails();
              }}
            >
              <IonIcon
                className="icon"
                style={{ padding: "11px", marginRight: 0 }}
                icon={informationCircleOutline}
              />
            </InternalLink>
          </IonCol>
          <IonCol className="ion-no-padding" style={{ fontSize: ".9em" }}>
            <IonLabel>
              <h4 style={showMinimalDetails ? { marginTop: "-2px" } : {}}>{facility.name}</h4>
              {!showMinimalDetails ? (
                <>
                  <p>{facility.fullAddress?.formatted ?? ""}</p>
                  <p>{formatPhone(facility.phone ?? "", usCountyCode)}</p>
                  <IonRow className="ion-align-items-center">
                    {distanceInMiles !== -1 && (
                      <div className="distance-note">
                        <DistanceNote
                          distance={distanceInMiles}
                          isApproxDistance={true}
                          homeIcon={true}
                        />
                      </div>
                    )}
                    {isExtraTimePayEnabled && (
                      <IonText
                        onClick={(event) => {
                          event.stopPropagation();
                          extraTimePayBottomSheetModalState.openModal();
                          logEvent(USER_EVENTS.VIEWED_EXTRA_TIME_PAY_ALERT, {
                            workerId: worker.userId,
                          });
                        }}
                      >
                        <span id="extra-time-pay-link">
                          <IonIcon size="small" icon={stopwatchOutline} /> Extra Time Pay Facility
                        </span>
                      </IonText>
                    )}
                  </IonRow>
                </>
              ) : null}
            </IonLabel>
          </IonCol>
        </IonRow>
      </IonGrid>
      {facility.checkInInstructions && isShiftToday && showCheckInInstructions && (
        <div>
          <IonCardSubtitle className="title no-text-transform ion-margin-bottom">
            Check-in Instructions
          </IonCardSubtitle>
          <p className="ellipsis">{facility.checkInInstructions}</p>
          <p className="ion-text-center no-padding">
            <u>see more</u>
          </p>
        </div>
      )}
      <ExtraTimePayBottomSheet
        facilityUserId={shift.facility!.userId!}
        modalState={extraTimePayBottomSheetModalState}
      />
    </>
  );
}
