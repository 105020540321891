import { IonButton, IonContent, IonIcon, IonPage } from "@ionic/react";
import { Shift, ShiftStages } from "@src/lib/interface/src";
import { bulbOutline } from "ionicons/icons";
import { useHistory, useLocation, useParams } from "react-router-dom";

import frowningImage from "public/assets/images/frowning.png";

import { TagLocationModalLink } from "./tagLocationModalLink";

interface LocationState {
  currentStage: ShiftStages;
  shift: Shift;
}

export const NfcFailurePage = () => {
  const { shiftId } = useParams<{ shiftId: string }>();
  const location = useLocation<LocationState>();
  const history = useHistory();

  const handleContinue = () => {
    const { currentStage, shift } = location.state;
    history.replace({
      pathname: `/home/myShifts/${shiftId}/nfcInstructions`,
      state: { currentStage, shift },
    });
  };

  const handleCancel = () => {
    history.replace(`/home/myShifts/${shiftId}`);
  };

  return (
    <IonPage>
      <IonContent fullscreen className="nfc-failure-page">
        <div className="nfc-failure-container">
          <div className="nfc-failure-title">Tag not detected.</div>
          <img className="nfc-failure-image" src={frowningImage} alt="Tag not detected" />
          <div className="nfc-failure-tips">
            <div className="nfc-failure-tip">
              <IonIcon icon={bulbOutline} />
              <div>
                Bad connection? <b>Try connecting to Wi-Fi.</b>
              </div>
            </div>
            <div className="nfc-failure-tip">
              <IonIcon icon={bulbOutline} />
              <div>
                It may help to <b>take off your phone case.</b>
              </div>
            </div>
            <div className="nfc-failure-tip">
              <IonIcon icon={bulbOutline} />
              <div>
                Make sure your phone <b>touches the tag</b> while scanning.
              </div>
            </div>
          </div>
          <div className="tag-not-found-link">
            <TagLocationModalLink />
          </div>
          <div className="nfc-failure-buttons">
            <IonButton size="large" expand="block" shape="round" onClick={handleContinue}>
              Retry
            </IonButton>
            <IonButton size="large" expand="block" fill="clear" onClick={handleCancel}>
              Cancel
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};
