import { IonButton, IonSpinner } from "@ionic/react";
import { SignatureContainerRef } from "@src/app/shiftSignature/signatureContainer";

import { SignatureContainer } from "../../../shiftSignature/signatureContainer";
import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "../../constants";

export const HcfSignatureInput = ({
  form,
  actions,
  signature,
}: {
  form: {
    hcfSignature: string;
    setHcfSignature: (n: string) => void;
    onStrokeStarted?: () => void;
  };
  actions: {
    goToStep: (nextStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
    onClear: () => void;
    submitFile: () => void;
  };
  signature: {
    hcfEmpName?: string;
    hcfSignaturePad: React.RefObject<SignatureContainerRef>;
    isUploadingImage: boolean;
    isShiftTimeEditOpen: boolean;
  };
}) => {
  const { hcfSignature, setHcfSignature, onStrokeStarted } = form;
  const { goToStep, onClear, submitFile } = actions;
  const { hcfEmpName, hcfSignaturePad, isUploadingImage, isShiftTimeEditOpen } = signature;

  return (
    <div id="hcfEmpSignatureSection">
      <div className="signature-pad-name-item">
        <p className="signature-pad-name">
          {hcfEmpName}
          <span>, please sign below.</span>
        </p>
      </div>
      <div className="signature-pad-canvas" aria-label="HCF Signature Pad">
        <SignatureContainer
          ref={hcfSignaturePad}
          onChange={setHcfSignature}
          signature={hcfSignature}
          onStrokeStarted={onStrokeStarted}
        />
      </div>

      <div className="sign-button-container">
        <IonButton
          fill="outline"
          shape="round"
          className="ion-margin sign-button"
          onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_INFO)}
        >
          Back
        </IonButton>
        <IonButton
          fill="outline"
          shape="round"
          className="ion-margin sign-button"
          onClick={onClear}
        >
          Clear
        </IonButton>
        <IonButton
          shape="round"
          color="primary"
          className="ion-margin sign-button"
          onClick={submitFile}
          disabled={!hcfSignature || isUploadingImage || isShiftTimeEditOpen}
        >
          <IonSpinner hidden={!isUploadingImage} slot="start" name="crescent" />
          {!isUploadingImage ? "Submit" : "Submitting"}
        </IonButton>
      </div>
    </div>
  );
};
