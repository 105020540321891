import { isDefined } from "@clipboard-health/util-ts";
import { IonAlert, IonButton, IonContent, IonPage, IonSpinner, isPlatform } from "@ionic/react";
import { OpenNativeSettings } from "@ionic-native/open-native-settings";
import { fetchShiftDetails, markShiftAsNonInstantPay } from "@src/app/api/shift";
import { AppType } from "@src/app/common/location/enable";
import { nfcSuccessMessage } from "@src/app/hcpShifts/components/nfc/utils/nfcSuccessMessage";
import { getShiftBreakDuration } from "@src/app/hcpShifts/helper";
import {
  actionRecordShiftTimeFailure,
  actionRecordShiftTimeWithNfc,
} from "@src/app/store/ongoingShifts/actions";
import { isCapacitorPlatform, useToast } from "@src/appV2/lib";
import { logError } from "@src/appV2/lib/analytics";
import { logEvent } from "@src/appV2/lib/analytics";
import { NfcHashValidationAction } from "@src/appV2/Shifts/NfcHashes/api/types";
import {
  NfcHashValidationResult,
  useNfcHashValidation,
} from "@src/appV2/Shifts/NfcHashes/api/useNfcHashValidation";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";
import { EXTENDED_NFC_SCAN_MAX_TIME, USER_EVENTS } from "@src/constants";
import {
  DeviceNFCCapabilityForShift,
  NFCReadStatus,
  NFCShiftConversionReason,
  SHIFT_MARKED_NON_IP_REASONS,
  Shift,
  ShiftStages,
} from "@src/lib/interface/src";
import { makeInstantpayLogParameters } from "@src/utils/logEvent";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";

import { useDeprecatedAlertsForNFC } from "./nfc/alert";
import { cancelNFCScan, deviceNFCCapabilityForShift, readNFCTagInfo } from "./nfc/nfcHelper";
import { getNFCReadLogReason, getNFCShiftConversionLogReason } from "./nfc/utils/nfcLogReasons";
import { TagLocationModalLink } from "./tagLocationModalLink";
import { getStageObject } from "../../components/shift/Stage";
import {
  MANDATORY_BREAK_DURATION_ALERT_THRESHOLD_IN_MINUTES,
  SHOW_NFC_NOT_WORKING_BUTTON_COUNT,
} from "../constants";

import "./style.scss";

/**
 * Note: This type contains additional properties compared to the Shift object,
 * thus it needs to be updated with the actual API response.
 */
type ShiftDetails = Shift;

interface LocationState {
  currentStage: ShiftStages;
  shift: ShiftDetails;
  areBreaksMandatory?: boolean;
  isSolveUnpaidBreaksEnabled: boolean;
}

function convertShiftStateToNfcAction(shiftStage: ShiftStages): NfcHashValidationAction {
  switch (shiftStage) {
    case ShiftStages.CLOCK_IN:
      return NfcHashValidationAction.CLOCK_IN;
    case ShiftStages.CLOCK_OUT:
      return NfcHashValidationAction.CLOCK_OUT;
    case ShiftStages.LUNCH_OUT:
      return NfcHashValidationAction.BREAK_START;
    case ShiftStages.LUNCH_IN:
      return NfcHashValidationAction.BREAK_END;
    case ShiftStages.SKIP_LUNCH:
      return NfcHashValidationAction.SKIP_BREAK;
    default:
      throw new Error("Invalid shift stage");
  }
}

function hashValidationResultToNfcReadStatus(
  validationResult: NfcHashValidationResult
): NFCReadStatus {
  switch (validationResult) {
    case NfcHashValidationResult.SUCCESS:
      return NFCReadStatus.SUCCESS;
    case NfcHashValidationResult.NETWORK_ERROR:
      return NFCReadStatus.NETWORK_FAILURE;
    case NfcHashValidationResult.INVALID_HASH:
    case NfcHashValidationResult.INCORRECT_HASH:
      return NFCReadStatus.INVALID_HASH;
    case NfcHashValidationResult.INACTIVE_HASH:
      return NFCReadStatus.INACTIVE_NFC_HASH;
    case NfcHashValidationResult.OTHER_FAILURE:
      return NFCReadStatus.FAILED;
    default:
      validationResult satisfies never;
      return NFCReadStatus.FAILED;
  }
}

const NfcInstructionsPage = () => {
  const { shiftId } = useParams<{ shiftId: string }>();
  const [shiftDetails, setShiftDetails] = useState<ShiftDetails>();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const [showButtonText, setShowButtonText] = useState(true);
  const [stageDescription, setStageDescription] = useState<string>();
  const nfcScanCounter = useRef(0);
  const nfcAlerts = useDeprecatedAlertsForNFC();
  const { alert } = nfcAlerts;
  const dispatch = useDispatch();
  const worker = useDefinedWorker();
  const [showCancelButton, setShowCancelButton] = useState(true);
  const location = useLocation<LocationState>();
  const { showSuccessToast, showErrorToast } = useToast();
  const { mutateAsync: validateNfcHash } = useNfcHashValidation();

  const handleBeginTap = async () => {
    setShowCancelButton(false);
    setShowButtonText(false);
    setIsLoading(true);
    await scanTag();
  };

  const handleCancelTap = () => {
    history.goBack();
  };

  /**
   * @todo Refactor this to use tanstack/react-query instead of this function
   * @todo Add correct typing for ShiftDetails based on what the API
   * provides. It currenlty only contains the Shift + NFC hasehs (which will be removed)
   */
  const loadShiftDetails = (shiftId: string): Promise<ShiftDetails> => {
    return fetchShiftDetails({ shiftId }) as unknown as Promise<ShiftDetails>;
  };

  /**
   * @todo Refactor this to use useMemo instead of useEffect.
   * It's easy to do, but it could break things because location.state is erased when we go to another page.
   * Refactoring it would require separate testing.
   */
  useEffect(() => {
    const loadCurrentStage = async () => {
      const { currentStage, shift, isSolveUnpaidBreaksEnabled = false } = location.state ?? {};
      // These fields might be empty if we navigate to another page
      if (!currentStage || !shift) {
        return;
      }
      setShiftDetails(shift);
      const { currentStageDescription } = getStageObject({
        shift,
        isSolveUnpaidBreaksEnabled,
      });
      setStageDescription(
        currentStage === ShiftStages.SKIP_LUNCH ? "skip your break" : currentStageDescription
      );
    };
    loadCurrentStage();
  }, [location.state]);

  const onCloseOrCancel = (onEditSuccess: boolean) => {
    // closeNFCScanner(onEditSuccess);
  };

  const openNFCSettings = async () => {
    if (isCapacitorPlatform()) {
      if (isPlatform("android")) {
        await OpenNativeSettings.open("nfc_settings");
        onCloseOrCancel(false);
      } else {
        await OpenNativeSettings.open("settings");
        onCloseOrCancel(false);
      }
    } else {
      setTimeout(() => {
        nfcAlerts.alertBrowserNFCAccess({
          gotItButtonHandler: () => {
            onCloseOrCancel(false);
          },
        });
      }, 500);
    }
  };

  const convertToNonInstantPay = async () => {
    try {
      await markShiftAsNonInstantPay(shiftId as string, SHIFT_MARKED_NON_IP_REASONS.NFC_FAILURE);
      createAccountLogForShiftConversion(NFCShiftConversionReason.SKIP_NFC_VALIDATION);
    } catch (error) {
      // FIXME - add proper error handling for this case which shows the error to the user
      logError(USER_EVENTS.CONVERT_SHIFT_TO_NON_INSTANT_PAY_ERROR, error);
    }
    history.goBack();
  };

  const showAlertForOtherError = () => {
    nfcAlerts.alertOtherIssue({
      gotItButtonHandler: () => {
        onCloseOrCancel(false);
      },
    });
  };

  const isDeviceSupported = async () => {
    const { currentStage } = location.state;
    const deviceNFCCapability = await deviceNFCCapabilityForShift();
    if (deviceNFCCapability === DeviceNFCCapabilityForShift.NO_NFC) {
      nfcAlerts.alertUserToRequestHCFToChangeToBackup({
        gotItBtnHandler: () => {
          onCloseOrCancel(false);
        },
      });
      return false;
    }
    if (deviceNFCCapability === DeviceNFCCapabilityForShift.NFC_DISABLED) {
      const isEdit = currentStage === ShiftStages.GET_FACILITY_SIGNATURE;
      nfcAlerts.alertNFCAccess({
        openNFCSettingsFn: () => {
          openNFCSettings();
        },
        skipNfcBtnHandler: isEdit ? () => onCloseOrCancel(false) : convertToNonInstantPay,
        isEdit: isEdit,
      });
      return false;
    }

    if (deviceNFCCapability !== DeviceNFCCapabilityForShift.NFC_ENABLED) {
      showAlertForOtherError();
      return false;
    }

    return deviceNFCCapability === DeviceNFCCapabilityForShift.NFC_ENABLED;
  };

  const showAlertForNFCError = (
    nfcReadStatus: NFCReadStatus = NFCReadStatus.INVALID_HASH,
    skipNfcBtnHandler: () => void,
    showNFCNotWorking = false,
    isEdit = false
  ) => {
    nfcAlerts.alertNFCError({
      showNFCNotWorking,
      isEdit: isEdit,
      nfcReadStatus,
      tryAgainBtnHandler: scanTag,
      skipNfcBtnHandler: skipNfcBtnHandler,
      okayButtonHandler: handleCancelTap,
    });
  };

  const scanTag = async () => {
    const canDeviceScan = await isDeviceSupported();
    if (canDeviceScan) {
      localStorage.setItem("isNFCOpen", "true");
      nfcScanCounter.current++;

      logEvent(USER_EVENTS.NFC_SCAN_START, {
        shiftId,
        facilityId: shiftDetails?.facilityId,
        agentId: shiftDetails?.agentId,
        shiftClockAction: location.state.currentStage,
        attemptNumber: nfcScanCounter.current,
      });

      let hasTimedOut = false;
      const timerForUpdateStage = setTimeout(async () => {
        hasTimedOut = true;
        await onNFCReadTimeout();
      }, EXTENDED_NFC_SCAN_MAX_TIME);

      const onSuccessRead = (readNFCData: string) => {
        if (hasTimedOut) {
          // the router will have switched to the NFC failure page by then
          return;
        }

        clearTimeout(timerForUpdateStage);
        updateStage(readNFCData, shiftDetails!);
      };

      await readNFCTagInfo(onSuccessRead);
    } else {
      resetPageButtons();
    }
  };

  const onNFCReadTimeout = async () => {
    logNfcFailure(NFCReadStatus.READ_TIMEOUT);

    const { currentStage, shift } = location.state;
    history.replace({
      pathname: `/home/myShifts/${shiftId}/nfcFailure`,
      state: { currentStage, shift },
    });
    await cancelNFCScan();
    resetPageButtons();
  };

  const updateStage = async (receivedNfcHash: string, shiftDetails: ShiftDetails) => {
    const { currentStage, isSolveUnpaidBreaksEnabled = false } = location.state;
    const { currentStageLog } = getStageObject({ shift: shiftDetails, isSolveUnpaidBreaksEnabled });

    const validationResult = await validateNfcHash({
      shiftId,
      hashString: receivedNfcHash,
      action: convertShiftStateToNfcAction(currentStage),
    });

    const nfcReadStatus = hashValidationResultToNfcReadStatus(validationResult);

    if (nfcReadStatus !== NFCReadStatus.SUCCESS) {
      if (currentStage === ShiftStages.GET_FACILITY_SIGNATURE) {
        showAlertForNFCError(
          nfcReadStatus,
          () => onCloseOrCancel(false),
          nfcScanCounter.current >= SHOW_NFC_NOT_WORKING_BUTTON_COUNT,
          true
        );
      } else {
        showAlertForNFCError(
          nfcReadStatus,
          convertToNonInstantPay,
          nfcScanCounter.current >= SHOW_NFC_NOT_WORKING_BUTTON_COUNT
        );
      }
      logNfcFailure(nfcReadStatus);
    } else {
      logEvent(USER_EVENTS.NFC_SCAN_SUCCEEDED, {
        shiftId,
        facilityId: shiftDetails?.facilityId,
        agentId: shiftDetails?.agentId,
        shiftClockAction: currentStage,
        attemptNumber: nfcScanCounter.current,
      });

      if (currentStage === ShiftStages.SHIFT_TIME_DONE) {
        // return
      } else if (currentStage === ShiftStages.GET_FACILITY_SIGNATURE) {
        showSuccessToast(nfcSuccessMessage(location.state.currentStage));
      } else {
        dispatch(
          actionRecordShiftTimeWithNfc(
            shiftId,
            currentStage,
            isPlatform("capacitor") ? AppType.MOBILE : AppType.WEB,
            ({ error }) => {
              if (error) {
                showErrorToast(error);
              } else {
                showSuccessToast(nfcSuccessMessage(location.state.currentStage));
              }

              onCloseOrCancel(false);
              history.goBack();
              resetPageButtons();
            }
          )
        );
        const logEventParams = makeInstantpayLogParameters(
          shiftDetails,
          shiftDetails.isInstantPay,
          undefined,
          location.state?.areBreaksMandatory
        );
        if (isDefined(currentStageLog)) {
          logEvent(currentStageLog, logEventParams);
        }

        if (currentStage === ShiftStages.SKIP_LUNCH) {
          logEvent(USER_EVENTS.SKIP_BREAK, logEventParams);
        }
        if (currentStage === ShiftStages.LUNCH_IN) {
          const breakDuration = getShiftBreakDuration(shiftDetails);
          if (breakDuration < MANDATORY_BREAK_DURATION_ALERT_THRESHOLD_IN_MINUTES) {
            logEvent(USER_EVENTS.BREAK_END_EARLY, { ...logEventParams, breakDuration });
          }
        }
      }
    }
  };

  const createAccountLogForShiftConversion = async (
    nfcShiftConversionReason: NFCShiftConversionReason
  ) => {
    const { currentStage } = location.state;
    const conversionReason = getNFCShiftConversionLogReason(
      nfcShiftConversionReason,
      shiftId,
      currentStage,
      shiftDetails
    );
    dispatch(actionRecordShiftTimeFailure(conversionReason, worker, shiftId as string));
  };

  const logNfcFailure = async (nfcReadStatus: NFCReadStatus) => {
    const attemptNumber = nfcScanCounter.current;
    const shift = await loadShiftDetails(shiftId);
    const { isSolveUnpaidBreaksEnabled = false } = location.state;
    const { currentStage } = getStageObject({ shift, isSolveUnpaidBreaksEnabled });
    const isEdit = currentStage === ShiftStages.GET_FACILITY_SIGNATURE;
    const failureReason = getNFCReadLogReason(false, nfcReadStatus, currentStage, shift, isEdit);

    dispatch(actionRecordShiftTimeFailure(failureReason, worker, shiftId as string));

    logEvent(USER_EVENTS.NFC_SCAN_FAILED, {
      shiftId,
      facilityId: shiftDetails?.facilityId,
      agentId: shiftDetails?.agentId,
      failureReason: nfcReadStatus,
      shiftClockAction: currentStage,
      attemptNumber,
    });
  };

  const resetPageButtons = () => {
    /**
     * Even if we navigate to another page,
     *  we still need to reset this page's state
     *  because Ionic's router reuses pages
     */
    setShowButtonText(true);
    setShowCancelButton(true);
    setIsLoading(false);
  };

  return (
    <IonPage>
      <IonContent className="nfc-instruction-page">
        <IonAlert
          header={alert?.header}
          message={alert?.message}
          isOpen={!!alert}
          // onDidDismiss will override alert to null if we try to change the alert inside a modal button handler
          onWillDismiss={nfcAlerts.dismissAlert}
          buttons={alert?.buttons}
          backdropDismiss={false}
          mode="ios"
        />
        <div className="nfc-instruction-content">
          <h4 className="nfc-instruction-info title">
            Tap your phone on the Clipboard tag to {stageDescription}
          </h4>
          <p className="nfc-instruction-info description" data-testid="nfc-instructions">
            The tags are placed on a white poster. Tap the tag with the{" "}
            <b>{isPlatform("android") ? "back center" : "top"}</b> of your phone.
          </p>
          <div className="nfc-instruction-info tag-not-found-link">
            <TagLocationModalLink />
          </div>
          <div className="nfc-image-container">
            <img
              className="nfc-tap-gif"
              src="/assets/gifs/nfcTap.gif"
              alt="Scan NCF tag"
              width={600}
              height="auto"
            />
          </div>
          <div className="nfc-instruction-info button-container">
            <>
              <IonButton
                shape="round"
                class="primary-btn  ion-margin-top"
                onClick={() => handleBeginTap()}
              >
                {showButtonText ? "Begin Tap" : ""}
                {isLoading && <IonSpinner name="crescent" />}
              </IonButton>
            </>
            {showCancelButton && (
              <IonButton class="nfc-scan-cancel-button" onClick={() => handleCancelTap()}>
                Cancel
              </IonButton>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export { NfcInstructionsPage };
