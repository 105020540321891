import { isDefined } from "@clipboard-health/util-ts";
import { IonButton, IonInput, IonItem, IonLabel } from "@ionic/react";
import { hideKeyboardOnKeyPressEnter } from "@src/app/common/ionInputHooks/hideKeyboardOnKeyPress";
import { capitalizeFirstLetterOfEveryWord } from "@src/lib/utils/src";

import { DIGITAL_TIMESHEET_SIGNATURE_STEPS } from "../../constants";

const isEmptyText = (t: string) => t.trim().length === 0;

export const HcfInfoInput = ({
  form,
  actions,
}: {
  form: {
    hcfStationWing: string;
    setHcfStationWing: (n: string) => void;
    hcfNursingService: string;
    setHcfNursingService: (n: string) => void;
  };
  actions: {
    goToStep: (nextStep: DIGITAL_TIMESHEET_SIGNATURE_STEPS) => void;
  };
}) => {
  const { hcfStationWing, setHcfStationWing, hcfNursingService, setHcfNursingService } = form;
  const { goToStep } = actions;

  const isContinueDisabled = isEmptyText(hcfStationWing) || isEmptyText(hcfNursingService);

  return (
    <div id="hcfEmpInfoSection">
      <div className="timesheet-summary-text signature-confirmation-prompt">
        <span>(Required) Please answer the</span>
        <br />
        <span>following questions.</span>
      </div>
      <div className="signature-employee-padding-keyboard">
        <IonItem lines="none" className="signature-employee-division">
          <IonLabel position="fixed" className="signature-employee-info-label">
            Station / Wing <br /> Unit / Floor
          </IonLabel>
          <IonInput
            className="signature-employee-name signature-employee-info-input"
            data-testid="input-hcfStationWing"
            placeholder="e.g. 2nd Floor"
            value={hcfStationWing}
            type="text"
            onKeyPress={hideKeyboardOnKeyPressEnter}
            onIonChange={(e) => {
              setHcfStationWing(
                isDefined(e.detail.value) ? capitalizeFirstLetterOfEveryWord(e.detail.value) : ""
              );
            }}
            clearInput
          ></IonInput>
        </IonItem>
        <IonItem lines="none" className="signature-employee-division">
          <IonLabel position="fixed" className="signature-employee-info-label">
            Nursing Services <br /> Assignment
          </IonLabel>
          <IonInput
            className="signature-employee-name signature-employee-info-input"
            data-testid="input-hcfNursingService"
            placeholder="e.g Acute Care"
            value={hcfNursingService}
            type="text"
            onKeyPress={hideKeyboardOnKeyPressEnter}
            onIonChange={(e) => {
              setHcfNursingService(
                isDefined(e.detail.value) ? capitalizeFirstLetterOfEveryWord(e.detail.value) : ""
              );
            }}
            clearInput
          ></IonInput>
        </IonItem>
      </div>
      <div className="sign-button-container">
        <IonButton
          fill="outline"
          shape="round"
          className="ion-margin sign-button"
          onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCP_SIGNATURE)}
        >
          Back
        </IonButton>
        <IonButton
          fill="solid"
          shape="round"
          color="primary"
          disabled={isContinueDisabled}
          className="ion-margin sign-button sign-confirmation-continue-button"
          onClick={() => goToStep(DIGITAL_TIMESHEET_SIGNATURE_STEPS.HCF_SIGNATURE)}
        >
          Continue
        </IonButton>
      </div>
    </div>
  );
};
