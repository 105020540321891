import { Text, Title } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box } from "@mui/material";
import { AgentStage } from "@src/appV2/Agents";
import {
  AttendanceScoreProfileResponse,
  LatenessConfigResponse,
} from "@src/appV2/AttendanceScore/api/useGetAttendanceScoreProfile";
import { ATeamHcpStatus } from "@src/appV2/Rankings/types";
import { useGetATeamWorkplaces } from "@src/appV2/Rankings/useGetATeamWorkplaces";
import { type Shift } from "@src/lib/interface";
import { differenceInHours, isAfter, parseISO } from "date-fns";
import { ReactElement } from "react";

import { MAX_ATTENDANCE_SCORE } from "../constants";
import { getPoints, getScoreColor } from "../utils/attendanceScore";

interface ShiftAttendanceScoreAccordionProps {
  shift: Shift;
  attendanceScoreProfile: AttendanceScoreProfileResponse;
}

export const clockedInMessage = (pointsForWorking: number) =>
  `You're clocked in. You'll earn ${pointsForWorking} points for working the shift to completion.`;

export const ongoingShiftMessage = `Running late? Tap "Late for Shift" below to update the facility. A No-Call-No-Show will result in an account Restriction. Facilities reserve the right to decline any late arrivals.`;

export const restrictedATeamWorkerMessage = `You're able to work this shift during your Restriction either because you have Priority Plus at this facility or you were invited. Arrive on time and avoid cancelling to stay in good standing with them.`;

export const restrictedNonATeamWorkerMessage = `You're able to work this shift during your Restriction either because you're a Favorite at this facility or you were invited. Arrive on time and avoid cancelling to stay in good standing with them.`;

export const urgentlyBookedShiftMessage = (onTimePoints: number) =>
  `Because this is a last minute shift, you won’t lose points for timeliness. You can still earn ${onTimePoints} extra points by arriving on time.`;

export const possibleRestrictionMessage = `A score of zero or below will result in an account Restriction.`;

// This function converts the lateness config to text of the form:
// "You'll lose 25 points for being >10 minutes late, 30 points for being >30 minutes late,
// and 50 points for being >50 minutes late"
function getLatenessPointsMessage(latenessConfig?: LatenessConfigResponse) {
  if (!isDefined(latenessConfig) || latenessConfig.length === 0) {
    return "";
  }
  const latenessPointsMessages = latenessConfig.map(
    (config) =>
      `${Math.abs(config.points)} points for being >${
        config.minutesLateRange.startFrom
      } minutes late`
  );
  const combinedLatenessPointsMessage =
    latenessPointsMessages.length === 1
      ? latenessPointsMessages.at(0)
      : latenessPointsMessages.slice(0, -1).join(", ") + ", and " + latenessPointsMessages.at(-1);

  return `You'll lose ${combinedLatenessPointsMessage}.`;
}

function getDescription(
  shift: Shift,
  attendanceScoreProfile: AttendanceScoreProfileResponse,
  aTeamStatus: ATeamHcpStatus
) {
  const isClockedIn = isDefined(shift.clockInOut?.start) && !isDefined(shift.clockInOut?.end);
  const workPoints = attendanceScoreProfile.policy?.workShift?.points ?? 0;

  if (isClockedIn) {
    return <Text>{clockedInMessage(workPoints)}</Text>;
  }

  const currentTime = new Date();
  const isShiftOngoing = isDefined(shift.start) && isAfter(currentTime, new Date(shift.start));
  if (isShiftOngoing) {
    return <Text>{ongoingShiftMessage}</Text>;
  }

  const isRestricted = attendanceScoreProfile?.accountStatus.status === AgentStage.RESTRICTED;
  if (isRestricted) {
    return aTeamStatus === ATeamHcpStatus.A_TEAM ? (
      <Text>{restrictedATeamWorkerMessage}</Text>
    ) : (
      <Text>{restrictedNonATeamWorkerMessage}</Text>
    );
  }

  const points = getPoints(attendanceScoreProfile, shift.start!);
  const isMaxScore = (attendanceScoreProfile?.score ?? 0) >= MAX_ATTENDANCE_SCORE;
  const showPossibleRestrictionMessage =
    attendanceScoreProfile.score -
      Math.max(points.cancelPointsNow, ...points.latenessPoints.map((config) => config.points)) <=
    0;

  return (
    <Text>
      <Text>
        {isMaxScore
          ? `You have the maximum score. You'll `
          : `You’ll earn ${points.workPoints} points for working the shift, and `}
        lose {points.cancelPointsNow} points if you cancel right now.
      </Text>
      <Text>
        {shift.urgentlyBooked
          ? urgentlyBookedShiftMessage(points.onTimePoints)
          : `Earn ${
              points.onTimePoints
            } extra points by arriving on time! ${getLatenessPointsMessage(
              attendanceScoreProfile.policy?.latenessConfig
            )}`}
      </Text>
      {showPossibleRestrictionMessage && !shift.urgentlyBooked && (
        <Text>{possibleRestrictionMessage}</Text>
      )}
    </Text>
  );
}

export function ShiftAttendanceScoreAccordion({
  shift,
  attendanceScoreProfile,
}: ShiftAttendanceScoreAccordionProps): ReactElement {
  const { aTeamStatus } = useGetATeamWorkplaces();

  const isRestricted = attendanceScoreProfile.accountStatus.status === AgentStage.RESTRICTED;
  const hoursBeforeShift = isDefined(shift.start)
    ? differenceInHours(parseISO(shift.start), new Date(), { roundingMethod: "ceil" })
    : 0;

  return (
    <>
      <Accordion defaultExpanded={hoursBeforeShift > 24}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ mx: 1 }} />}>
          <Title bold component="h4" data-testid="attendance-score-title">
            Attendance Score:{" "}
            <Box
              component="span"
              sx={{
                color: isRestricted ? "red" : getScoreColor(attendanceScoreProfile.score),
              }}
            >
              {isRestricted ? "Restricted" : attendanceScoreProfile.score}
            </Box>
          </Title>
        </AccordionSummary>
        <AccordionDetails data-testid="attendance-score-description">
          {getDescription(shift, attendanceScoreProfile, aTeamStatus)}
        </AccordionDetails>
      </Accordion>
    </>
  );
}
